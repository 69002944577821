<template>
  <div>
    <br />
    <h2>Inicio de sesión alternativo</h2>
    <v-scroll-x-transition mode="out-in">
      <div>
        <br />
        <v-icon
          color="primary"
          v-if="alternativeLoginSuccessful"
          class="image-success"
          size="100"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        <v-icon
          color="primary"
          v-if="alternativeLoginError"
          class="image-success"
          size="100"
          >mdi-alert-circle-outline</v-icon
        >
        <v-row class="justify-center" v-if="loading" no-gutters>
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"
          ></v-progress-circular>
          <br />
        </v-row>
        <br />
        <v-row class="justify-center" v-if="!alternativeLoginError" no-gutters>
          <h2 class="letters-color">Por favor espere</h2>
        </v-row>
        <v-row class="justify-center" v-else no-gutters>
          <h2 class="letters-color">Lo sentimos</h2>
        </v-row>
        <br />
        <v-row class="justify-center" v-if="!alternativeLoginError" no-gutters>
          <p class="letters-color">
            En unos momentos se le redirigirá a la aplicación
          </p>
        </v-row>
        <v-row class="justify-center" v-else no-gutters>
          <p class="letters-color">
            El plazo para iniciar sesión ha vencido o ha ocurrido un error
          </p>
        </v-row>
        <br />
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import jwt from "@/common/jwt.service";
import Repository from "@/services/repositories/repositoryFactory";
const UserRepository = Repository.get("users");

export default {
  name: "alternative-login-section",
  data() {
    return {
      alternativeLoginSuccessful: false,
      loading: false,
      alternativeLoginError: false
    };
  },
  methods: {
    async alternativeLogin() {
      this.loading = true;
      const user = { username: jwt.decodeToken().username };
      await UserRepository.authorizeAlternative(user)
        .then(res => {
          this.$store.dispatch("users/setUser", res);
          this.alternativeLoginSuccessful = true;
          this.$router.push({ name: `dashboard` });
        })
        .catch(() => {
          this.alternativeLoginError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.alternativeLogin();
  }
};
</script>

<style scoped>
.venusa-title-button ::v-deep .v-btn__content {
  color: white !important;
}

.venusa-title-button ::v-deep .v-btn__loader {
  color: white !important;
}

.letters-color {
  color: var(--v-primary-base);
}

.letters-cursor {
  cursor: pointer;
}

.image-success {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}
</style>
