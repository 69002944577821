<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-lazy transition="slide-y-transition">
          <alternative-login-card></alternative-login-card>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import AlternativeLoginCard from "../../components/alternativeLogin/AlternativeLoginCard";
export default {
  name: "alternative-login-view",
  components: {
    "alternative-login-card": AlternativeLoginCard
  },
  data() {
    return {};
  },
  mounted() {
    this.toggleBackgroundDark(true);
  },
  methods: mapMutations("ux", ["toggleBackgroundDark"])
};
</script>

<style></style>
